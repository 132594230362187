/* eslint-disable react/jsx-indent */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-multi-assign */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React, { useEffect, useMemo, useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppMountPoints } from '@apps/AppMounts'
import MountPoint from '@engine/components/MountPoint'
import {
  getProvince,
  setLandingLogicLoader,
  APPLICATION_STATE,
  ApplicationContext,
  ModifyApplicationContext
} from '@apps/contexts/ApplicationContext'
import useSite from '@engine/hooks/useSite'
import {
  updatePages,
  updateProvinces,
  changeSelectedLanguage,
  changeSelectedProvince,
  recordAccountInfo,
  setQuoteInfo,
  recordQuoteId,
  recordUserInfo,
  recordQuoteByEmailInfo
} from '@apps/redux/features/CommonSlice'
import {
  defaultPlan,
  loadLocalizedPlanItem,
  updatePlansState,
  PLANS_STATE
} from '@apps/redux/features/PlansSlice'
import { recordSimSelectionInfo } from '@apps/redux/features/SimSelectionSlice'
import { updateAccountSetupState } from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import ProxyOperations from './services/operations'
import useLocale from '@/_engine/hooks/useLocale'
import { useHistory } from 'react-router'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import cookie from 'react-cookies'
import { convertCentsToDollars } from '@apps/flows/activation/services/accountHelper'
import Sites from '@sites/index'
import { recordPhoneNumberInfo } from '@/apps/redux/features/PhoneNumberSlice'
import { getById, getBySlug } from '@apps/utils/contentful'
import { LANGUAGES_CODE, PROMO_TYPE } from '@/apps/utils/constants'
import { navigateToPath } from '@apps/utils/navigation'
import Brands from '@brands/index'

const {
  retrieveAccountByEmail,
  retrieveQuoteByEmail,
  getAllPagesData,
  createQuote,
  getPlanList,
  sendEcpEmail
} = ProxyOperations(Brands.public)

const { getProvinces } = ProxyOperations(Brands.koodo)

const ActivationRoutes = () => {
  const { lang, prov } = useLocale()
  const dispatch = useDispatch()
  const history = useHistory()
  const site = useSite()
  const {
    content: { pageContent, featureFlags },
    provinces,
    userType,
    user: { email: userEmail, referrerUrl, isLoggedIn, accountByEmailInfo },
    quote: { quoteByEmailInfo }
  } = useSelector((state) => state.common)

  const { plan, localizedPlanItem } = useSelector((state) => state.plansPage)
  const paymentState = useSelector((state) => state.payAndReviewPage)
  const {
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: multiPromoCodes
  } = paymentState

  const [quoteByEmailInfoResponse, setQuoteByEmailInfoResponse] = useState({})

  const appState = useContext(ApplicationContext)
  const setAppState = useContext(ModifyApplicationContext)

  const { [APPLICATION_STATE.SERVICES]: brandServices } = appState

  const { retrieveCookie } = brandServices

  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const page = getById(pageContent, `haveSimPage`) || {}
  const simSelectionPageContent = page[lang] || {}
  const { shortTextFields } = simSelectionPageContent
  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      eSimCost: getBySlug(shortTextFields, 'eSimCost')?.value,
      pSimCost: getBySlug(shortTextFields, 'pSimCost')?.value
    }
  }, [shortTextFields, userType])

  const eSimPrice = parseFloat(textContent?.eSimCost) ?? 0
  const pSimPrice = parseFloat(textContent?.pSimCost) ?? 0

  const KEY_PLAN_ID = 'plan'
  const PLAN_NOT_SELECTED = 'PLAN_NOT_SELECTED'

  const retrievePlans = async (plansResponse) => {
    try {
      const updatedPlansResponse = {}
      Object.keys(plansResponse).forEach((language) => {
        updatedPlansResponse[language] = []
        const languagePlans = plansResponse?.[language]

        languagePlans?.forEach((langPlans) => {
          const { plans, displayFeaturedPlan } = langPlans
          const updatedPlans = plans?.map((currPlan) => {
            if (currPlan?.promo) {
              const bundleArray = currPlan?.promo?.bundles?.map((bundle) => {
                const { id, name, fee: rawFee, bundleType, unitType, numberMrc } = bundle

                return {
                  id,
                  name,
                  fee: convertCentsToDollars(rawFee),
                  bundleType,
                  unitType,
                  numberMrc
                }
              })
              const promo = {
                id: currPlan?.promo?.id,
                description: currPlan?.promo?.description,
                bundles: bundleArray
              }
              const updatedPlan = { ...currPlan }
              updatedPlan.promo = promo
              return updatedPlan
            }
            return currPlan
          })
          updatedPlansResponse[language].push({ displayFeaturedPlan, plans: updatedPlans })
        })
      })
      dispatch(loadLocalizedPlanItem(updatedPlansResponse))
      return updatedPlansResponse
    } catch (err) {
      console.log('error while retrieving plans', err)
    }
  }

  const fetchData = async () => {
    try {
      const [provincesResult, planListResult, allPageDataResult] = await Promise.all([
        getProvinces(),
        getPlanList('', site, false),
        getAllPagesData(site)
      ])

      dispatch(updateProvinces(provincesResult))
      setAppState({ ...appState, [APPLICATION_STATE.PROVINCES]: provincesResult })

      retrievePlans(planListResult)

      dispatch(updatePages(allPageDataResult))
    } catch (error) {
      console.err('Error fetching data', error)
    }
  }

  useEffect(() => {
    if (pageContent) {
      return
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!pageContent) {
      return
    }
    if (userType === Sites.consumer) {
      initiateLandingPage(consumerActivationPaths)
    } else if (userType === Sites.dealer) {
      initiateLandingPage(dealerActivationPaths)
    }
  }, [userEmail, pageContent, userType, isLoggedIn])

  useEffect(() => {
    dispatch(changeSelectedLanguage(lang))
    dispatch(changeSelectedProvince(prov.toUpperCase()))
  }, [lang, prov])

  const getRetrieveAccountByEmail = async (email, subScriStatus) => {
    let result
    let isAlreadyActivated = false
    let autheticateData

    try {
      if (email) {
        if (accountByEmailInfo?.data) {
          autheticateData = accountByEmailInfo?.data
        } else {
          const retrieveAccountByEmailResponse = await retrieveAccountByEmail(email)
          const { data, error: autheticateError } = retrieveAccountByEmailResponse
          autheticateData = data
          if (autheticateError) {
            // console.log('AutheticateError error:', autheticateError)
            return { result: null, isAlreadyActivated: false }
          }
        }

        const {
          roles,
          statusCode,
          profile: { subscriberId, subscriberStatus, subscriberGuid, ban }
        } = autheticateData
        result = autheticateData

        const kPreUcareAccess = roles.some((role) => role === 'KPRE_UCARE_ACCESS')

        if (kPreUcareAccess) {
          dispatch(recordAccountInfo(autheticateData))
          if (userType === Sites.consumer) {
            history.push(consumerActivationPaths.AlreadyActivated)
          } else {
            history.push(dealerActivationPaths.AlreadyActivated)
          }
          isAlreadyActivated = true
        }
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(err, 'error while retrieving account by email')
    }
    return { result, isAlreadyActivated }
  }

  const processQuoteData = async (quoteData, activationPaths) => {
    if (quoteData?.quoteId) {
      dispatch(setQuoteInfo(quoteData))

      const { fullName, provinceId, language, dynamicCode, SimInformation } = quoteData
      if (fullName) {
        const [firstName, lastName] = fullName.split(' ')
        dispatch(
          updateAccountSetupState({
            firstName,
            lastName,
            pin: dynamicCode,
            confirmPin: dynamicCode,
            language: language === LANGUAGES_CODE.EN ? 0 : 1
          })
        )
      }

      if (provinceId) {
        const { shortName } = getProvince(provinceId)
        dispatch(
          updatePhoneNumberState({
            [PHONE_NUMBER_STATE.PROVINCE_ID]: provinceId,
            [PHONE_NUMBER_STATE.PROVINCE_NAME]: shortName
          })
        )
      }

      if (
        userType === Sites.dealer &&
        (quoteData.currentState === 'Phone Number Selection' ||
          quoteData.currentState === 'Plan Selection' ||
          quoteData.currentState === 'Payment Information') &&
        SimInformation?.simType === 'ESIM' &&
        !SimInformation.eid
      ) {
        history.push(activationPaths.SimSelection)
      } else {
        await navigateToPath(quoteData.currentState, activationPaths, history, lang, prov)
      }
    }
  }

  const getRetrieveQuoteByEmail = async (email, activationPaths) => {
    let response

    try {
      if (quoteByEmailInfo?.data || userEmail === email) {
        if (quoteByEmailInfo?.data) {
          response = quoteByEmailInfo
        } else {
          setQuoteByEmailInfoResponse((prevState) => {
            response = prevState
          })
        }
      } else {
        response = await retrieveQuoteByEmail({ email })
        dispatch(recordQuoteByEmailInfo(response))
        setQuoteByEmailInfoResponse(response)
      }

      if (response?.data) {
        await handlePlanSelection(
          response?.data?.pricePlanId,
          response?.data?.isPlanSelected,
          response?.data?.provinceId
        )
        processQuoteData(response.data, activationPaths)
        dispatch(setQuoteInfo(response.data))
      }
    } catch (err) {
      console.log(err, 'error while retrieving quote information')
      history.push(activationPaths.Splash)
    }
    return response
  }

  const setCreateQuote = async (input) => {
    const cameFromPlanSelection = referrerUrl?.includes('/activation/plans')
    let result
    try {
      result = await createQuote({
        customerId: input,
        pricePlanId: plan?.id,
        pricePlanGuid: plan?.guid,
        fullName: '',
        language: lang,
        isPlanSelected: cameFromPlanSelection
      })
      if (result?.data?.quoteId) {
        dispatch(recordQuoteId(result?.data))

        if (plan?.id) {
          cookie.save(KEY_PLAN_ID, plan?.id, { path: '/' })
        }

        let ecpPayload = {}
        ecpPayload.locale = lang
        ecpPayload.email = input
        sendEcpEmail(ecpPayload)
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(err, 'error while creating quote')
    }
    return result
  }

  const getUserInfo = async () => {
    let userInfo = {}
    const { userSessionId, userEmail, isLoggedIn } = ({} = (await retrieveCookie()) || {})
    userInfo.email = userEmail
    userInfo.isLoggedIn = isLoggedIn
    if (userSessionId && userInfo && userInfo?.email && isLoggedIn) {
      dispatch(recordUserInfo(userInfo))
    }

    return userInfo
  }

  const waitForUserInfo = async (delay = 1000) => {
    await new Promise((resolve) => setTimeout(resolve, delay))
    const userInfo = await getUserInfo()
    return userInfo
  }

  const initiateLandingPage = async (activationPaths) => {
    const subscriptionStatus = [
      'active',
      'suspended',
      'expired',
      'lost-stolen / fermé',
      'draft',
      'preactivate'
    ]
    const userInfo = await waitForUserInfo()

    if (userInfo.isLoggedIn) {
      setLandingLogicLoader(true)
    }

    if (!userInfo || !userInfo.email || !userInfo.isLoggedIn || !isLoggedIn) {
      if (userInfo.isLoggedIn) {
        setLandingLogicLoader(true)
      } else {
        setLandingLogicLoader(false)
        return
      }
    }

    const {
      result: retrieveAccountByEmailData,
      isAlreadyActivated
    } = await getRetrieveAccountByEmail(userInfo?.email, subscriptionStatus)

    if (retrieveAccountByEmailData && !isAlreadyActivated) {
      const retrieveQuoteData = await getRetrieveQuoteByEmail(userInfo?.email, activationPaths)

      if (
        retrieveQuoteData?.data?.quoteState?.toUpperCase() === 'DELIVERED' ||
        retrieveQuoteData?.data?.quoteState?.toUpperCase() === 'SUBMITTED_FOR_DELIVERY'
      ) {
        setLandingLogicLoader(false)
        history.push(activationPaths.ProcessFailure)
        return
      }

      if (
        retrieveQuoteData?.data?.quoteId === null &&
        !subscriptionStatus.includes(retrieveAccountByEmailData?.subscriberStatus?.toLowerCase())
      ) {
        const createQuoteData = await setCreateQuote(userInfo?.email)
        if (createQuoteData?.data?.quoteId) {
          history.push(activationPaths.AccountSetup)
          setLandingLogicLoader(false)
        } else {
          setLandingLogicLoader(false)
          history.push(activationPaths.Splash)
        }
      }

      dispatch(
        recordSimSelectionInfo({ ...retrieveQuoteData?.data?.SimInformation, eSimPrice, pSimPrice })
      )

      if (retrieveQuoteData?.data?.UserData && retrieveQuoteData?.data?.UserData?.portIn !== null) {
        dispatch(recordPhoneNumberInfo(retrieveQuoteData?.data?.UserData))
      }

      setLandingLogicLoader(false)
    }
    setLandingLogicLoader(false)
  }

  const setSelectedPlan = (selectedPlan, allPlans, isPreSelected) => {
    dispatch(
      updatePlansState({
        [PLANS_STATE.PLAN]: selectedPlan,
        [PLANS_STATE.PRE_SELECTED_PLAN]: isPreSelected,
        [PLANS_STATE.SELECTED_PLAN_OPTION]: selectedPlan.duration,
        [PLANS_STATE.ALL_PLANS]: allPlans
      })
    )
    if (selectedPlan?.promo) {
      if (
        featureFlags &&
        featureFlags[0].enableMultiPromo &&
        multiPromotionCodeInfo?.length === 0 &&
        multiPromoCodes?.length === 0
      ) {
        dispatch(
          updatePaymentStates({
            [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: [selectedPlan.promo],
            [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: [PROMO_TYPE.AUTOMATIC_PROMO]
          })
        )
      }
    }
  }

  const validPlanForProvince = (preSelectedPlan, provinceId) => {
    const { shortName = '' } = getProvince(provinceId)
    return preSelectedPlan?.province?.find((pr) => pr?.toUpperCase() === shortName.toUpperCase())
  }

  const handlePlanSelection = async (pricePlanId, isPlanSelected, provinceId) => {
    let currentPlan
    const langPlans = localizedPlanItem?.[lang]
    const plansObject = langPlans?.find(Boolean) || {}
    const { plans = [] } = plansObject

    if (pricePlanId && pricePlanId !== PLAN_NOT_SELECTED && plans.length > 0) {
      currentPlan = plans?.find((planData) => planData?.id === pricePlanId)
      if (!currentPlan) {
        history.push(consumerActivationPaths.ChangeExistingPlan)
      }
    }

    if (currentPlan) {
      setSelectedPlan(currentPlan, plans, isPlanSelected)
    } else if (isPlanSelected === false) {
      if (plan?.id && validPlanForProvince(plan, provinceId)) {
        setSelectedPlan(plan, plans, isPlanSelected)
      } else {
        setSelectedPlan(defaultPlan, plans, isPlanSelected)
      }
    }
  }

  return (
    <div className="relative min-h-screen bg-background">
      <MountPoint name={AppMountPoints.activationMount} />
    </div>
  )
}

export default ActivationRoutes
